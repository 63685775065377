import Vue from "vue";
import App from "./App.vue";
import router from './router';
import store from './store'
import Axios from "axios";
import '@babel/polyfill'

import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.config.productionTip = false;

Vue.use({
    install(Vue) {
        let axiosTemp = Axios.create({
            // baseURL: 'http://127.0.0.1:8000/api/',
            baseURL: 'https://api.smartmeasure-energy.hosting.erkoware.de/api/',
        });

        axiosTemp.interceptors.request.use(function (config) {
            // Do something before request is sent
            config.headers["Authorization"] = 'Bearer ' + localStorage.getItem("user");
            return config;
        });

        axiosTemp.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('user')
                router.replace({name: 'Login'})
            }
            return Promise.reject(error);
        });

        Vue.prototype.$api = axiosTemp;
    }
})

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
