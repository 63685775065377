<template>
    <b-sidebar
        class="sidebar"
        :visible="$route.path !== '/login' && $route.path !== '/'"
        no-slide
        no-header
        no-close-on-route-change
        shadow="lg"
        width="250px"
        bg-variant="dark"
        text-variant="light"
        backdrop-variant="dark"
    >
        <template v-slot:default>
            <div class="p-1">
                <!-- Replace with Logo -->
                <b-icon
                    icon="terminal"
                    style="width: 120px; height: 120px"
                    class="m-2"
                />
                <b-nav vertical>
                    <b-nav-item
                        v-for="link in links"
                        :key="link.route"
                        link-classes="m-1 nav-item-custom d-flex align-items-center justify-content-start"
                        :to="{ name: link.route }"
                    >
                        <b-icon
                            :icon="link.icon"
                            class="mr-4"
                            font-scale="2"
                        ></b-icon>
                        <div style="font-size: 1.3em">{{ link.name }}</div>
                    </b-nav-item>
                </b-nav>
            </div>
        </template>

        <template v-slot:footer>
            <b-btn variant="outline-light" @click="logout" class="mb-4">
                Logout
                <b-icon class="ml-1" icon="arrow-right-square"/>
            </b-btn>
        </template>
    </b-sidebar>
</template>

<script>
import Vue from "vue";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

export default {
    name: "Sidebar",
    methods: {
        logout() {
            localStorage.removeItem("user");
            this.$router.replace("/login");
        },
    },
    data() {
        return {
            links: [
                {
                    name: "Dashboard",
                    route: "Dashboard",
                    icon: "house",
                },
                {
                    name: "Live View",
                    route: "Liveview",
                    icon: "camera-video",
                },
                {
                    name: "Reports",
                    route: "Reports",
                    icon: "bar-chart-line",
                },
                {
                    name: "Manage",
                    route: "Manage",
                    icon: "gear",
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
@import "../assets/styles/custom.scss";

* {
    text-align: center;
}

.nav-item-custom {
    color: $light;
}

.nav-item-custom:hover {
    color: $dark;
    background-color: $light;
}

.nav-item-custom.router-link-active {
    color: $dark;
    background-color: $light;
    font-weight: bold;
}
</style>
