<template>
    <div id="app">
        <Sidebar/>
        <!--<Header />-->

        <router-view/>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
// import Header from "@/components/Header";

export default {
    name: "App",
    components: {
        Sidebar,
        // Header
    },
    methods: {},
};
</script>

<style lang="scss">
@import "./assets/styles/custom.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
</style>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    /*color: #2c3e50;*/
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #363941;
}

#nav a.router-link-exact-active {
    color: #dedede;
}

.router-styling {
    margin-left: 230px;
    width: 80%;
    margin-top: 40px;
}
</style>
