import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        meta: {
            auth: true
        },
        redirect: '/dashboard'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            auth: true
        },
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: '/liveview',
        name: 'Liveview',
        meta: {
            auth: true
        },
        component: () => import('../views/Liveview.vue')
    },
    {
        path: '/reports',
        name: 'Reports',
        meta: {
            auth: true
        },
        component: () => import('../views/Reports.vue')
    },
    {
        path: '/manage',
        name: 'Manage',
        meta: {
            auth: true
        },
        component: () => import('../views/Manage.vue'),
    },

    // otherwise redirect to home
    {path: '*', redirect: '/'}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !localStorage.getItem('user')) {
        next({name: 'Login'})
    } else if (to.name === 'Login' && localStorage.getItem('user')) {
        next('/')
    } else {
        next()
    }
})

export default router;
